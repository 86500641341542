.searchCardContainer {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-bottom: 2rem;
}
.searchCardHeader {
  display: flex;
  border-radius: 0.8rem 0.8rem 0 0;
  align-items: center;
  background-color: var(--header-background-color);
  color: var(--secondary-color-light);
}
.searchTitle {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: bold;
  font-size: 18px;
  height: 2.8rem;
  align-items: center;
  margin: 0 1.2rem;
  padding: 0 0.24rem;
}
.searchTitleMobile {
  color: var(--text-color);
  font-weight: bold;
  font-size: 18px;
  margin: 0.8rem 1.2rem;
}
.headerRightJustified {
  display: flex;
  margin-left: auto;
}
.headerLabelContainer {
  margin: 0 1.2rem;
  padding: 1.6rem 0;
}
.activeDot {
  display: inline-block;
  width: 10px;

  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 0.8rem;
}
.activeDot.inactiveSearch {
  background-color: red;
}
.headerLabelContainer p:first-child {
  /* This is the styling for the label of what the stat is  */
  display: flex;
  align-items: center;
  font-size: small;
  font-weight: bold;
  margin: 0;
}
.labelIcon {
  font-size: 90%;
  margin-right: 0.2rem;
}
.headerLabelContainer p:last-child {
  /* This is the stylign for the stat itself */
  font-size: normal;
  font-weight: bold;
  margin: 0.24rem 0 0 0;
}
/* Styles that are the sames */
.searchCardBody {
  height: 18rem;
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-color-dark);
  border-radius: 0 0 0.8rem 0.8rem;
}
.searchCardBodyMobile {
  height: 14rem;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color-dark);
  border-radius: 0 0 0.8rem 0.8rem;
}

.golfCourseImagePreview {
  height: 14rem;
  width: 21.24rem;
  overflow: hidden;
  border-radius: 0.8rem;
  margin: 0 1.2rem 1.2rem 1.2rem;
}
.imageTitleContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}
.rightHalfCardBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottomHalfCardBodyMobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bottomHalfCardBodyMobile > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
}
.rightHalfCardBody > div {
  display: flex;
  width: 100%;
}
.searchCardParameters {
  color: var(--text-color);
  width: 100%;
  height: 30%;
  justify-content: flex-start;
}
.searchCardParameters > .headerLabelContainer:first-child {
  /* Want a larger margin between these elementes */
  margin-left: 1.2rem;
  width: 45%;
}
.searchCardParameters > .headerLabelContainer:nth-child(2) {
  /* Want a larger margin between these elementes */
  width: 30%;
}
.searchCardParameters > .headerLabelContainer:last-child {
  /* Want a consistent margin on the last element to line up with the padding above */
  margin: 0 1.2rem 0 0;
}
.searchCardParametersMobile {
  color: var(--text-color);
  width: 100%;
  justify-content: flex-start;
  height: 100%;
}
.labelSearchInfoMobile {
  width: 100%;
  margin: 0 1.2rem;
  font-weight: bold;
}
.searchActionsContainer {
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.tee-time-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: auto;
  margin-left: 1.2rem;
}

.tee-time-item {
  background-color: rgba(255, 255, 255, 0.02);
  color: var(--text-color);
  border-radius: 0.8rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.tee-time {
  font-weight: bold;
  margin-right: 0.5rem;
}

.player-count {
  display: flex;
  align-items: center;
}

.player-icon {
  font-size: 1rem;
  margin-right: 0.25rem;
}

.player-number {
  font-weight: bold;
}
.searchActionIcons {
  width: 30%;
  justify-content: flex-end;
  align-items: flex-end;
}
.searchActionButtons {
  display: flex;
  flex-direction: column;
}

.iconLabeledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.2rem 1.2rem 1.2rem 0;
  font-size: 1.6rem;
  height: 2.88rem;
  width: 2.88rem;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0.01);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  color: var(--secondary-color-light);
  transition: background-color 0.2s linear;
}
.iconLabeledButton:hover {
  /* Make it slightly lighter */
  background-color: rgba(255, 255, 255, 0.04);
}
.iconLabeledButton:active {
  /* give the click effect */
  background-color: var(--primary-color);
}
.spinner_ajPY {
  transform-origin: center;
  animation: spinner_AtaB 0.75s infinite ease-in;
}

@keyframes spinner_AtaB {
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 1000px) {
  .iconLabeledButton {
    font-size: 2rem;
    height: 3rem;
    width: 3rem;
    /* height: fit-content;
    width: fit-content; */
    padding: 0;
    transform: scale(1);
  }
}
