.timePickerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  height: 48px;
  width: 240px;
  background-color: var(--secondary-color-dark);
  border-radius: var(--border-radius-standard);
  padding: 0 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.01);
}

input[type='time']::-webkit-calendar-picker-indicator {
  pointer-events: none;
  display: none;
}

.timePickerInput {
  background-color: transparent;
  padding: 10px;
  border-radius: var(--border-radius-standard);
  font-size: 18px;
  text-align: center;
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 110px;
  color: var(--text-color);
  transition: background-color 0.1s ease;
}

.startTimeInputBox,
.endTimeInputBox {
  margin: 0 4px;
}

.timePickerInput:hover {
  cursor: text;
  background-color: rgba(255, 255, 255, 0.05);
}

/* Disabling default webkit */
input[type='time']::-webkit-datetime-edit-hour-field:focus,
input[type='time']::-webkit-datetime-edit-minute-field:focus,
input[type='time']::-webkit-datetime-edit-second-field:focus,
input[type='time']::-webkit-datetime-edit-ampm-field:focus {
  background-color: var(--secondary-color-light);
  color: var(--text-color);
}

@media (max-width: 700px) {
  /* If it is mobile, move the time closer together*/
  .timePickerContainer {
    width: 200px;
  }
}
