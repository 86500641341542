.searchDetailsContainer {
  padding: 20px 0;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-color-dark);
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 0.8rem;
  margin: 32px;
}

.leftHalfInfoBlock {
  justify-content: center;
  margin-left: 10px;
  margin-right: 5px;
}
.halfWidthInfoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: calc(50% - 15px);
  height: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
}
.rightHalfInfoBlock {
  margin-left: 5px;
  margin-right: 10px;
}

.searchCriteriaContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  padding: 20px 0px;
}

.searchCriteriaContainer > .searchButtonHomePage {
  background-color: var(--secondary-color-dark);
  margin: 0px 0px 8px 0px;
}

.searchCriteriaContainer > button.searchButtonHomePage.disabledButton {
  color: var(--secondary-color-light);
  background-color: var(--secondary-color-dark);
  border-color: var(--secondary-color-light);
  margin: 0px 0px 8px 0px;
}

.searchCriteriaContainer > .disabledButton:hover {
}
.d-button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.02);
  color: var(--accent-color);
  min-width: 10rem;
  border: none;
  border-radius: var(--border-radius-standard);
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
  max-height: 42px;
  transition:
    background-color 0.4s,
    color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.button-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-button-style:hover {
  background-color: var(--accent-color);
  color: var(--secondary-color-dark);
  cursor: pointer;
}

.d-button-style:disabled {
  background-color: rgba(255, 255, 255, 0.02);
  pointer-events: none;
}

.button-text {
}

.search-loading-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
}
/* MOBILE STYLING */
/* Check if the screen is too small for it to be displayed the box format, if so make it a column */
@media (max-width: 700px) {
  .searchDetailsContainer {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    height: min(80vh, 600px);
    /* background-color: transparent;
    box-shadow: none; */
  }
  .halfWidthInfoBlock {
    width: 100%;
  }
  .searchCriteriaContainer {
    align-items: center;
  }
}
