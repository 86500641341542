.featuredCoursesBanner {
  height: fit-content;
  margin: 100px 0 0 0;
}

.teeSearchFormContainer {
  width: min(calc(100% - 10px), 700px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: var(--secondary-color-dark); */
}
#homePageContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
}
#courseDetailsContainer {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  margin-top: 30px;
  height: 177.5px;
  width: 100%;
}
.courseSelectWrapper {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.dividerLineDiv {
  visibility: hidden;
  height: 2px;
  border-radius: 2px;
  background-color: var(--secondary-color);
  width: calc(100% - 30px);
  margin: 20px 0px;
}
.courseSelectContainer {
  width: 100%;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 0.8rem;
}
.courseSelectLoading {
  width: 100%;
  height: 64px;
  background-color: var(--secondary-color-dark);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.courseSelectLoading > div.skeleton-text-title {
  margin: 0 1.2rem;
  width: 30%;
}
/* Mobile styles */
@media (max-width: 700px) {
  .featuredCoursesBanner {
    height: 30%;
  }
  .teeSearchFormContainer {
    margin-top: 10vh;
    height: 100vh;
  }
}
