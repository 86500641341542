.searchTableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  margin-top: 8rem;
  border-radius: var(--border-radius-standard);
}
.noSearchesFoundContainer > .noSearchesFoundIcon {
  font-size: 5rem;
}
.noSearchesFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
  font-size: 1.04rem;
}
.noSearchesFoundContainer > p {
  color: var(--secondary-color-light);
}
.searchTableHeader {
  display: flex;
  width: 100%;
  height: 2.44rem;
  margin: 0;
  align-items: center;
}
.headerDividerLine {
  width: 100%;
  border-color: var(--secondary-color-light);
  border-width: 0 0 2px 0;
  border-radius: 1px;
  margin: 0 0 2.4rem 0;
}
.dashboardHeaderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.68rem;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.12s linear;
  margin: 0;
  font-size: 1.6rem;
  height: 100%;
  width: 2.44rem;
}
.dashboardHeaderButton:hover {
  background-color: rgba(255, 255, 255, 0.04);
  cursor: pointer;
}
.dashboardHeaderButton:active {
  background-color: var(--background-color);
  cursor: default;
}
.searchFilterButtonContainer {
  height: 100%;
  margin: 0 0 0 2rem;
}
.activeFilterButton {
  background-color: var(--secondary-color);
}
.filterButtonActive,
.filterButtonInactive {
  height: 100%;
  width: 4.8rem;
  box-sizing: border-box;
  font-size: 1.08rem;
  border-radius: 0.6rem 0.6rem 0 0;
  transition: background-color 0.14s linear;
  transition: box-shadow 0.14s linear;
  background-color: var(--background-color);
  color: var(--text-color);
}
.filterButtonActive:hover,
.filterButtonInactive:hover {
  transition: background-color 0.14s linear;
  background-color: rgba(255, 255, 255, 0.04);
}
.filterButtonActive:active,
.filterButtonInactive:active {
  transition: background-color 0.14s linear;
  background-color: var(--primary-color);
}
.filterButtonActive {
  background-color: rgba(255, 255, 255, 0.08);
  transition: box-shadow 0.14s linear;
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px -12px 38px,
    rgba(0, 0, 0, 0.22) 0px -9px 12px;
}
.navigationButtonContainer {
  height: 100%;
  display: flex;
  margin-left: auto;
}
.navigationButtonContainer > .dashboardHeaderButton {
  /* All Nav Buttons */
  color: var(--secondary-color-light);
}
.navigationButtonContainer > p.dashboardHeaderButton {
  box-sizing: border-box;
  pointer-events: none;
  font-size: 1.12rem;
  padding: 1px 0;
}
.searchTableLoading {
  width: 1000px;
  padding: auto;
  margin-bottom: 1.2rem;
}
.searchTableLoading > .searchCardBody {
}
.searchCardBody > .loadingBodyItems {
  margin: auto 1.2rem;
  display: flex;
}
.searchCardHeader > div.skeleton-text-title {
  margin: 1.2rem;
  width: 250px;
}
.searchTableLoading > .searchCardHeader {
  height: 89px;
}
.searchCardBody > div > div.skeleton-text {
  margin-left: 1.2rem;
  width: 100px;
  height: 2rem;
}
.searchCardBody > div > .skeleton-image {
  width: 340px;
  height: 224px;
}
@media (max-width: 1000px) {
  .dashboardHeaderButton {
    width: fit-content;
    padding: 0 0.4rem;
  }
  /* Preventing the look that the buttons are "stuck on" */
  .dashboardHeaderButton:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  .searchTableLoading,
  .searchTableContainer {
    width: 90%;
  }
  .filterButtonActive,
  .filterButtonInactive {
    width: fit-content;
    padding: 0 0.6rem;
  }
  .searchFilterButtonContainer {
    height: 100%;
    margin: 0 0 0 0.2rem;
  }
}
