.playerCountSelectContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 180px;
  background-color: rgba(255, 255, 255, 0.01);
  border-radius: var(--border-radius-standard);
  padding: 0 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.playerCountButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-standard);
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  color: var(--text-color);
  transition: background-color 0.2s ease;
  margin: 0 2px;
}

.playerCountButton:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
}

.activePlayerCount {
  background-color: var(--accent-color) !important;
  color: var(--secondary-color-dark);
  border-color: var(--accent-color);
}

.inputFieldLabel {
  color: var(--text-color);
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
}

.playerSelectComponentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
