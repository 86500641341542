@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /*Color Pallette*/
  --primary-color: #21252b;
  --secondary-color-light: #646a75;
  --secondary-color: #333842;
  --secondary-color-dark: #22272b;
  --header-background-color: #101214;
  --accent-color: #e7c343;
  --text-color: #cccccc;
  --background-color: #1a1e22;
  /*Border Settings*/
  --border-radius-standard: 0.6rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
div,
label {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
}
button:hover {
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.flex-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
